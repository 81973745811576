
import { Options, Vue } from 'vue-class-component'
import { Dialog, Toast } from 'vant'
@Options({
  components: {}
})
export default class Pay extends Vue {
  copy () {
    Dialog.alert({
      title: '温馨提示',
      message: '复制成功'
    })
  }
}
